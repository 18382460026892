<template>
  <div v-if="isHasUpdates" class="toolbar flex jc-c">
    <button class="toolbar__btn btn outline pl-10 pr-10 jc-c rounded" @click="submitAction(currentFilter, true)">
      <BaseIcon class="ic-20 secondary" icon="update" /> Есть новые посты
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, ref, computed } from 'vue'
import { BaseIcon } from '@/components'
import { socketConnect } from '@/plugins'

// Emits
const emits = defineEmits(['load'])

// Data
const store = useStore()
const currentFilter = ref('')
const isHasUpdates = ref(false)

// Computed
const profile = computed(() => store.getters.profile)

// Created
wsConnect()
if (localStorage.getItem('choco_news-filter')) {
  currentFilter.value = localStorage.getItem('choco_news-filter')
}

// Methods
async function submitAction(filter, isUpdate = false) {
  if (currentFilter.value !== filter || isUpdate) {
    currentFilter.value = filter
    emits('load', true)
    if (filter) {
      localStorage.setItem('choco_news-filter', filter)
      await store.dispatch('news/GET_ROWS', { [filter]: 1 })
    } else {
      localStorage.removeItem('choco_news-filter')
      await store.dispatch('news/GET_ROWS')
    }
    const scrlContent = document.querySelector('.default-main')
    scrlContent.scroll({ top: 0, left: 0, behavior: 'smooth' })
    emits('load', false)
    isHasUpdates.value = false
  }
}

// Methods:websocket
function wsConnect() {
  const name = `private-post.${profile.value?.id}`

  socketConnect.channel(name).listen('.PostCreatedPrivateEvent', () => {
    isHasUpdates.value = true
  })
}
</script>

<style lang="scss" scoped>
.toolbar {
  box-shadow: none;
  width: max-content;
  margin: 0 auto;

  &__btn {
    &:hover {
      box-shadow: var(--box-shadow);
    }
  }
}
</style>
