<template>
  <RouterView />
  <div class="news flex fd-c ggap-10">
    <ToolbarSmall class="news__toolbar z3" @load="isLoad = $event" />
    <div class="grid ggap-10 pos-r z1">
      <BaseLoad v-if="isLoad" class="grid jc-c z3 bg white box" />
      <template v-if="posts && posts.length">
        <ItemPost v-for="post of posts" :key="post.id" :item="post" link="news" />
        <BaseLoad v-if="isLoadMore" class="rel sm" />
      </template>
      <div v-else class="p-20 ta-center box">
        <h2 class="title mb-5">Упс, тут пока что пусто!</h2>
        <small class="t-grey-ed">Подпишитесь на сообщества, чтоб следить за публикациями</small>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { BaseLoad, ItemPost } from '@/components'
import ToolbarSmall from './components/ToolbarSmall.vue'

// Data
const store = useStore()
const isLoad = ref(false)
const isLoadMore = ref(false)

// Computed
const posts = computed(() => store.getters['news/rows'])
const pages = computed(() => store.getters['news/pages'])

// Created
getRows()

// Mounted
onMounted(() => {
  const el = document.querySelector('.default-main')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 2000) await getNextRows()
  })
})

// UnMounted
onUnmounted(() => {
  store.commit('news/CLEAR')
})

// Methods
async function getRows() {
  try {
    isLoad.value = true
    if (localStorage.getItem('choco_news-filter')) {
      const filter = localStorage.getItem('choco_news-filter')
      await store.dispatch('news/GET_ROWS', { [filter]: 1 })
    } else {
      await store.dispatch('news/GET_ROWS')
    }
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
async function getNextRows() {
  if (pages.value?.current_page < pages.value?.last_page && !isLoadMore.value) {
    try {
      isLoadMore.value = true
      const params = {
        page: pages.value.current_page + 1,
        postId: posts.value[0].id
      }
      if (localStorage.getItem('choco_news-filter')) {
        const filter = localStorage.getItem('choco_news-filter')
        params[filter] = 1
      }
      await store.dispatch('news/GET_ROWS', params)
    } catch (error) {
      console.log(error)
    } finally {
      isLoadMore.value = false
    }
  }
}
</script>

<style lang="scss">
.news {
  &__toolbar {
    position: sticky;
    top: 0;
    box-shadow: 0 -30px 0 0 var(--bg);
  }
}
</style>
